<div class="routed-page">
    <div class="filter-container">
        <div class="field">
            <div class="title">Nome</div>
            <input type="text" [(ngModel)]="f_name">
        </div>
        <div class="field">
            <div class="title">Codice cliente</div>
            <input type="text" [(ngModel)]="f_customer_code">
        </div>
        <div class="field-button-list">
            <div class="title">Filtri</div>
            <div class="button-list">
                <div class="button button-primary" (click)="onFilterApplyClicked()">Applica</div>
                <div class="button button-secondary" (click)="onFilterResetClicked()">Reset</div>
            </div>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="_actions">
                <th mat-header-cell *matHeaderCellDef>
                    <plus-circle-outline-icon class="field clickable"
                        (click)="onAddCustomer()"></plus-circle-outline-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="icon-list">
                        <archive-box-x-mark-outline-icon class="field clickable" matTooltip="Cancella cliente"
                            (click)="onDeleteCustomer(element)"></archive-box-x-mark-outline-icon>
                        <pencil-outline-icon class="field clickable" matTooltip="Modifica informazioni cliente"
                            (click)="onEditCustomer(element)"></pencil-outline-icon>
                        <computer-desktop-outline-icon class="field clickable"
                            matTooltip="Visualizza terminali del cliente"
                            (click)="onNavigate('terminal/' + element.customer_name)"></computer-desktop-outline-icon>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.customer_email}} </td>
            </ng-container>
            <ng-container matColumnDef="cu_code">
                <th mat-header-cell *matHeaderCellDef> Codice cliente </th>
                <td mat-cell *matCellDef="let element"> {{element.cu_code}} </td>
            </ng-container>
            <ng-container matColumnDef="cu_note">
                <th mat-header-cell *matHeaderCellDef> Note </th>
                <td mat-cell *matCellDef="let element"> {{element.cu_note}} </td>
            </ng-container>
            <ng-container matColumnDef="total_clocks">
                <th mat-header-cell *matHeaderCellDef> N. Timbratori </th>
                <td class="clickable" mat-cell *matCellDef="let element"
                    (click)="onNavigate('terminal/' + element.customer_name)"> {{element.total_clocks}} </td>
            </ng-container>
            <ng-container matColumnDef="cu_api_key">
                <th mat-header-cell *matHeaderCellDef> API KEY </th>
                <td mat-cell *matCellDef="let element"> {{element.cu_api_key || '-'}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>