<div class="modal-container">
    <div class="modal-header">
        <div class="modal-title">
            <ng-container *ngIf="data">
                Modifica dati cliente
            </ng-container>
            <ng-container *ngIf="!data">
                Aggiungi cliente
            </ng-container>
        </div>
        <div class="modal-close">
            <x-circle-outline-icon class="field-clickable" (click)="closeMe()"></x-circle-outline-icon>
        </div>
    </div>
    <div class="modal-content">
        <form class="grid grid-2" [formGroup]="form">
            <div class="field field-grid">
                <div class="title">Nome cliente</div>
                <input type="text" formControlName="name">
            </div>
            <div class="field field-grid">
                <div class="title">Indirizzo email</div>
                <input type="text" formControlName="mail">
            </div>
            <div class="field field-grid">
                <div class="title">Codice cliente</div>
                <input type="text" formControlName="cu_code">
            </div>
            <div class="field field-grid">
                <div class="title">Note</div>
                <input type="text" formControlName="cu_note">
            </div>
            <div class="field field-grid">
                <div class="title">API KEY</div>
                <input type="text" formControlName="cu_api_key">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="button" [ngClass]="canSendRequest ? 'button-primary' : 'button-disabled'"
            (click)="canSendRequest && onSaveCustomer()">Salva</div>
    </div>
</div>