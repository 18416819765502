<div class="routed-page">
    <ng-container *ngIf="isGettingMetrics">
        <div class="abs-spinner">
            <app-spinner></app-spinner>
        </div>
    </ng-container>
    <ng-container *ngIf="!isGettingMetrics">
        <div class="card-list">
            <div class="card">
                <div class="title">Risorse sistema</div>
                <div class="content">
                    <div class="card-item">
                        <div class="key">Utilizzo CPU</div>
                        <div class="value">{{metrics?.cpu?.usage}}%</div>
                    </div>
                    <div class="card-item">
                        <div class="key">Utilizzo RAM</div>
                        <div class="value">{{metrics?.memory?.used}}%</div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="title">Server</div>
                <div class="content">
                    <div class="card-item">
                        <div class="key">Uptime</div>
                        <div class="value" [matTooltip]="formatUptime(metrics?.uptime, true)">
                            {{formatUptime(metrics?.uptime, false)}}</div>
                    </div>
                    <div class="card-item">
                        <div class="key">Query da orologi</div>
                        <div class="value">{{metrics?.queryFromClocks}}</div>
                    </div>
                    <div class="card-item">
                        <div class="key">Query a DB</div>
                        <div class="value">{{metrics?.queryToDatabase}}</div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="title">Report totali</div>
                <div class="content">
                    <div class="card-item">
                        <div class="key">Clienti</div>
                        <div class="value">{{metrics?.db?. totalCustomers}}</div>
                    </div>
                    <div class="card-item">
                        <div class="key">Terminali</div>
                        <div class="value">{{metrics?.db?. totalClocks}}</div>
                    </div>
                    <div class="card-item">
                        <div class="key">Timbrature</div>
                        <div class="value">{{metrics?.db?. totalAttlogs}}</div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="title">
                    <div>
                        Modelli terminali
                    </div>
                    <div>
                        <plus-circle-outline-icon class="clickable" [matTooltip]="'Aggiungi modello'"
                            (click)="upsertClockModel()"></plus-circle-outline-icon>
                    </div>
                </div>
                <div class="content">
                    <ng-container *ngFor="let model of clockModelList">
                        <div class="card-item">
                            <div class="key">
                                <div>
                                    {{model.cm_name}}
                                </div>
                                <div> - </div>
                                <div class="italic">
                                    {{model.cm_desc}}
                                </div>
                            </div>
                            <div class="value">
                                <pencil-outline-icon class="clickable" matTooltip="Modifica modello"
                                    (click)="upsertClockModel(model)"></pencil-outline-icon>
                                <archive-box-x-mark-outline-icon class="clickable" matTooltip="Elimina modello"
                                    (click)="deleteClockModel(model)"></archive-box-x-mark-outline-icon>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!clockModelList?.length">
                        <span class="italic">Nessun modello disponibile.</span>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
