<div class="routed-page">
    <ng-container *ngIf="isInError">
        Errore nel recuperare la lista clienti.
    </ng-container>
    <ng-container *ngIf="!isInError">
        <div class="filter-container">
            <div class="field f-field-long">
                <div class="title">Cliente</div>
                <mat-select [(ngModel)]="f_customer_name">
                    <input class="filterSearch" placeholder="Cerca cliente"
                        (keyup)="onCustomerListFilter($event.target)">
                    <mat-option *ngFor="let c of customerList" [value]="c.customer_name">
                        {{c.customer_name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="field f-field">
                <div class="title">Stato</div>
                <mat-select [(ngModel)]="f_status">
                    <mat-option *ngFor="let status of statusList" [value]="status">
                        {{status}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="field-button-list">
                <div class="title">Filtri</div>
                <div class="button-list">
                    <div class="button button-primary" (click)="onFilterApplyClicked()">Applica</div>
                    <div class="button button-secondary" (click)="onFilterResetClicked()">Reset</div>
                </div>
            </div>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="_actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-list">
                            <plus-circle-outline-icon class="field clickable"
                                (click)="onAddTerminal()"></plus-circle-outline-icon>
                            <!-- <clock-outline-icon class="field clickable" (click)="onSyncClocks()"></clock-outline-icon> -->
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="icon-list">
                            <archive-box-x-mark-outline-icon class="field clickable" matTooltip="Elimina terminale"
                                (click)="onDeleteTerminal(element)"></archive-box-x-mark-outline-icon>
                            <pencil-outline-icon class="field clickable" matTooltip="Modifica informazioni terminale"
                                (click)="onEditClock(element)"></pencil-outline-icon>
                            <bars-4-outline-icon class="field clickable" matTooltip="Visualizza timbrature terminale"
                                (click)="onNavigate('stamps/' + element.c_sn)"></bars-4-outline-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="c_custom_id">
                    <th mat-header-cell *matHeaderCellDef> ID Terminale </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_custom_id}} </td>
                </ng-container>
                <ng-container matColumnDef="c_sn">
                    <th mat-header-cell *matHeaderCellDef> Numero Seriale </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_sn}} </td>
                </ng-container>
                <ng-container matColumnDef="c_name">
                    <th mat-header-cell *matHeaderCellDef> Nome </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_name}} </td>
                </ng-container>
                <ng-container matColumnDef="c_model">
                    <th mat-header-cell *matHeaderCellDef> Modello </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_model}} </td>
                </ng-container>
                <ng-container matColumnDef="c_note">
                    <th mat-header-cell *matHeaderCellDef> Note </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_note}} </td>
                </ng-container>
                <ng-container matColumnDef="c_desc">
                    <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_desc || "-"}} </td>
                </ng-container>
                <ng-container matColumnDef="c_location">
                    <th mat-header-cell *matHeaderCellDef> Ubicazione </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_location || "-"}} </td>
                </ng-container>
                <ng-container matColumnDef="customer_name">
                    <th mat-header-cell *matHeaderCellDef> Cliente </th>
                    <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
                </ng-container>
                <ng-container matColumnDef="c_local_ip">
                    <th mat-header-cell *matHeaderCellDef> IP Locale </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_local_ip}} </td>
                </ng-container>
		<ng-container matColumnDef="c_minutes">
                    <th mat-header-cell *matHeaderCellDef> Offset minuti </th>
                    <td mat-cell *matCellDef="let element"> {{element.c_minutes}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th class="th-div-center" mat-header-cell *matHeaderCellDef> Stato </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="td-div-center">
                            <span [matTooltip]="element.tooltip">
                                <ng-container *ngIf="element.online">
                                    <wifi-outline-icon svgStyle="stroke: #35f325;"></wifi-outline-icon>
                                </ng-container>
                                <ng-container *ngIf="!element.online">
                                    <ng-container *ngIf="element.c_last_timestamp === '-1'">
                                        <signal-slash-outline-icon svgStyle="stroke: #000;"></signal-slash-outline-icon>
                                    </ng-container>
                                    <ng-container *ngIf="element.c_last_timestamp !== '-1'">
                                        <signal-slash-outline-icon
                                            svgStyle="stroke: #be2a2a;"></signal-slash-outline-icon>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                        <div class="td-div-center">
                            {{element.tooltip}}
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-container>
</div>
