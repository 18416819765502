<div class="modal-container">
    <div class="modal-header">
        <div class="modal-title">
            <ng-container *ngIf="data?.cm_id">
                Modifica dati modello
            </ng-container>
            <ng-container *ngIf="!data?.cm_id">
                Aggiungi modello
            </ng-container>
        </div>
        <div class="modal-close">
            <x-circle-outline-icon class="field-clickable" (click)="closeMe()"></x-circle-outline-icon>
        </div>
    </div>
    <div class="modal-content">
        <form class="grid grid-2" [formGroup]="form">
            <div class="field field-grid">
                <div class="title">Nome modello</div>
                <input type="text" formControlName="cm_name">
            </div>
            <div class="field field-grid">
                <div class="title">Descrizione modello</div>
                <input type="text" formControlName="cm_desc">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="data?.cm_id">
            <div class="button" (click)="canSendRequest && onUpdateClockModel()"
                [ngClass]="[canSendRequest ? 'button-primary': 'button-disabled']">Aggiorna modello</div>
        </ng-container>
        <ng-container *ngIf="!data?.cm_id">
            <div class="button" (click)="canSendRequest && onAddClockModel()"
                [ngClass]="[canSendRequest ? 'button-primary': 'button-disabled']">Aggiungi modello</div>
        </ng-container>
    </div>
</div>