<div class="routed-page">
    <div class="filter-container">
        <div class="field">
            <div class="title">Terminale SN</div>
            <mat-select [(ngModel)]="f_terminalSN">
                <input class="filterSearch" placeholder="Cerca terminale" (keyup)="onClockListFilter($event.target)">
                <mat-option *ngFor="let c of clockList" [value]="c.c_sn">
                    {{c.c_sn}}
                </mat-option>
            </mat-select>
        </div>
        <div class="field">
            <div class="title">Badge</div>
            <input type="text" #f_userId>
        </div>
        <div class="field-material f-field f-field-long">
            <div class="title">Cliente</div>
            <mat-select [(ngModel)]="f_customer_name">
                <input class="filterSearch" placeholder="Cerca cliente" (keyup)="onCustomerListFilter($event.target)">
                <mat-option *ngFor="let c of customerList" [value]="c.customer_name">
                    {{c.customer_name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="field-material">
            <div class="title">Data</div>
            <input type="text" [(ngModel)]="f_date" matInput ngxDaterangepickerMd class="f_date">
        </div>
        <div class="field-material">
            <div class="title">Ubicazione</div>
            <input type="text" [(ngModel)]="f_clock_location">
        </div>
        <div class="field-material">
            <div class="title">Modello</div>
            <mat-select [(ngModel)]="f_c_model">
                <mat-option *ngFor="let c of clockModelList" [value]="c.cm_name">
                    {{c.cm_name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="field-material f-field f-field-long">
            <div class="title">Stato</div>
            <mat-select [(ngModel)]="f_sent">
                <mat-option *ngFor="let c of f_sent_list" [value]="c.id">
                    {{c.value}}
                </mat-option>
            </mat-select>
        </div>
        <div class="field-button-list">
            <div class="title">Filtri</div>
            <div class="button-list">
                <div class="button button-primary" (click)="onFilterApplyClicked()">Applica</div>
                <div class="button button-secondary" (click)="onFilterResetClicked()">Reset</div>
                <document-text-outline-icon class="clickable" matTooltip="Scarica timbrature"
                    (click)="onDownloadStamps()"></document-text-outline-icon>
            </div>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="attlog_terminal_sn">
                <th mat-header-cell *matHeaderCellDef> Terminale SN </th>
                <td mat-cell *matCellDef="let element"> {{element.attlog_terminal_sn}} </td>
            </ng-container>
            <ng-container matColumnDef="attlog_user_id">
                <th mat-header-cell *matHeaderCellDef> Badge </th>
                <td mat-cell *matCellDef="let element"> {{element.attlog_user_id}} </td>
            </ng-container>
            <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef> Cliente </th>
                <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
            </ng-container>
            <ng-container matColumnDef="attlog_date">
                <th mat-header-cell *matHeaderCellDef> Data </th>
                <td mat-cell *matCellDef="let element"> {{formatDate(element.attlog_date)}} </td>
            </ng-container>
            <ng-container matColumnDef="attlog_time">
                <th mat-header-cell *matHeaderCellDef> Ora </th>
                <td mat-cell *matCellDef="let element"> {{formatTime(element.attlog_time)}} </td>
            </ng-container>
            <ng-container matColumnDef="attlog_access_type">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.attlog_access_type}} </td>
            </ng-container>
            <ng-container matColumnDef="clock_location">
                <th mat-header-cell *matHeaderCellDef> Ubicazione terminale </th>
                <td mat-cell *matCellDef="let element"> {{element.clock_location || '-'}} </td>
            </ng-container>
            <ng-container matColumnDef="attlog_reason_code">
                <th mat-header-cell *matHeaderCellDef> Verso </th>
                <td mat-cell *matCellDef="let element"> {{element.attlog_reason_code}} </td>
            </ng-container>
            <ng-container matColumnDef="attlog_work_code">
                <th mat-header-cell *matHeaderCellDef> Codice lavoro </th>
                <td mat-cell *matCellDef="let element"> {{element.attlog_work_code || '-'}} </td>
            </ng-container>
            <ng-container matColumnDef="attlog_sent_timestamp">
                <th mat-header-cell *matHeaderCellDef> Inviato il </th>
                <td mat-cell *matCellDef="let element"> {{formatDate(element.attlog_sent_timestamp)}} </td>
            </ng-container>
            <ng-container matColumnDef="_actions">
                <th mat-header-cell *matHeaderCellDef>
                    <ng-container *ngIf="dataSource?.filteredData?.length">
                        <paper-airplane-outline-icon matTooltip="Segna tutti come da inviare" class="clickable"
                            (click)="MarkAllAttlogToBeSent()"></paper-airplane-outline-icon>
                    </ng-container>
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.attlog_sent_timestamp">
                        <paper-airplane-outline-icon svgClass="s-attlog-sent" matTooltip="Segna come da inviare"
                            class="clickable" (click)="markAttlogAsToBeSent(element)"></paper-airplane-outline-icon>
                    </ng-container>
                    <ng-container *ngIf="!element.attlog_sent_timestamp">
                        <paper-airplane-outline-icon svgClass="s-attlog-not-sent" matTooltip="Segna come da inviare"
                            class="clickable" (click)="markAttlogAsToBeSent(element)"></paper-airplane-outline-icon>
                    </ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="paginator">
        <div class="button" matTooltip="Pagina precedente"
            [ngClass]="[prevEnabled ? 'button-primary' : 'button-disabled']"
            (click)="prevEnabled && onPaginatorClicked('prev')">
            < </div>
                <div class="button" matTooltip="Pagina successiva"
                    [ngClass]="[nextEnabled ? 'button-primary' : 'button-disabled']"
                    (click)="nextEnabled && onPaginatorClicked('next')">></div>
        </div>
    </div>