<div class="navbar">
    <div class="title">
        Rilevazione Presenze
    </div>
    <div class="right-bar">
        <div class="current-page bold">
            {{currentPage}}
        </div>
        <div class="credits">
            Made by
            <span (click)="gotoCredits()">
                Jacopo Sciampi
            </span>
        </div>
        <div class="field">
            <user-outline-icon></user-outline-icon>
            {{username}}
        </div>
        <div class="field clickable" (click)="authService.logout()" matTooltip="Logout">
            <arrow-right-on-rectangle-outline-icon></arrow-right-on-rectangle-outline-icon>
            Logout
        </div>
    </div>
</div>