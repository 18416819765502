<div class="modal-container">
    <div class="modal-header">
        <div class="modal-title">
            Eliminazione modello
        </div>
        <div class="modal-close">
            <x-circle-outline-icon class="clickable" (click)="closeMe()"></x-circle-outline-icon>
        </div>
    </div>
    <form class="modal-content modal-content-flex-v-center">
        <div>
            Nome modello: {{data.cm_name}}
        </div>
        <div>
            Descrizione: {{data.cm_desc}}
        </div>
        <div class="bold">
            Attenzione! L'operazione è irreversibile.
        </div>
        <div class="bold red-big" *ngIf="showConfirm">
            Confermare di nuovo l'eliminazione.
        </div>
    </form>
    <div class="modal-footer">
        <div class="button" [ngClass]="canDelete ? 'button-primary' : 'button-disabled'"
            (click)="canDelete && onDeleteTerminal()">Conferma eliminazione</div>
    </div>
</div>
